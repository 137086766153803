import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Iframe from 'react-iframe';

export default function Contato(props) {
    useEffect(() => {
        document.title = 'Modular - ATHIS';
      }, []);
  return (
    <div>
        <Box className="contato maincontent">
        <Iframe 
                //url="https://forms.office.com/Pages/ResponsePage.aspx?id=QWnnJ77-cEmwyBLR4Vu4T3X1YmFsW0NCpGQnxqEOc59UMFk0RlIzRlMzOUtONkdTRVkyNVFKQVFDRC4u&embed=true"
                url="https://web.powerva.microsoft.com/environments/Default-27e76941-febe-4970-b0c8-12d1e15bb84f/bots/crd24_contato/webchat?__version__=2"
                width="100%"
                height="100%"
                id="contato"
                className=""
                display="block"
                position="relative"
                frameborder="0"
                marginwidth="0"
                marginheight="0"
                style="position: relative;display: block;border-width: 0!important;"
                //allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen
               />
        </Box>
    </div>
  );
}