import { useEffect } from "react";import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Projetos from "./components/projetos";
import './App.css'
import SideBar from "./components/appBar";
import Home from "./components/home";
import Equipe from "./components/equipe";
import Associado from "./components/associado";
import Sobre from "./components/sobre";
import Contato from "./components/contato";
import Sharepoint from "./components/sharepoint";
import PostSP from "./components/postSP";
import Transparencia from "./components/transparencia";

function App() {
  useEffect(() => {
    document.title = 'Modular ATHIS';
  });
  return (
    <div
      className="App"
    >
      <Router>
      <SideBar />
        <div className="body">
          <Routes className="body">
            <Route >
              <Route path="/" element={<Home />} exact />
              <Route path="/equipe" element={<Equipe />} exact />
              <Route path="/sobre" element={<Sobre />} exact />
              <Route path="/contato" element={<Contato />} exact />
              <Route path="/projetos" element={<Projetos />} exact />
              <Route path="/projetos/:tagParam" element={<Projetos />} exact />
              <Route path="/associado/:idDoUserParam" element={<Associado />} exact />
              <Route path="/posts" element={<Sharepoint categoria="Post" />} exact />
              <Route path="/posts/:tagParam" element={<Sharepoint categoria="Post" />} exact />
              <Route path="/transparencia" element={<Transparencia />} exact />
              <Route path="/post/:postUrl" element={<PostSP />} exact />
            </Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
