import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Typography from '@mui/material/Typography';
import { Link, useParams } from "react-router-dom";
import UserFE from "./userFE";
import Axios from 'axios'
import { Skeleton } from "@mui/material";


export default function UserCard(params) {
  const user = params.user;
  return (
    <Box className={params.location + " associado_card"}>
      <div alignItems="flex-start" className="cards_item user" key={user.id} >
        <div className="user_avatar"
          alt={user.primeiro_nome}
          style={{
            backgroundImage: "url(https://reactwebsiteb594.blob.core.windows.net/imagens/" + user.email + ")",
            backgroundPosition: 'center',
            backgroundSize: '120px 120px',
          backgroundRepeat: 'no-repeat'}} >
          <ul className="social_equipe">
            <li className="lattes_equipe" >
              <a href={user.lattes ? user.lattes : "#"} target="_blank" rel="noopener noreferrer" title="Currículo Lattes">
                <img className="social-icon-equipe lazy loaded" src="/media/lattes.webp" width="20" height="20" data-was-processed="true" alt="Currículo Lattes"/>
              </a>
            </li>
            <li className="instagram_equipe" >
              <a href={user.instagram ? user.instagram : "#"} target="_blank" rel="noopener noreferrer" title="Instagram">
                <img className="social-icon-equipe lazy loaded" src="/media/instagram1.webp" width="20" height="20" data-was-processed="true" alt="Instagram"/>
              </a>
            </li>
            <li className="linkedin_equipe" >
              <a href={user.linkedIn ? user.linkedIn : "#"} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                <img className="social-icon-equipe lazy loaded" src="/media/linkedin1.webp" width="20" height="20" data-was-processed="true" alt="LinkedIn"/>
              </a>
              </li>
          </ul>
        </div>
        <div className="user_data">
        <Link to={user.email ? `/associado/${user.email.replaceAll("@modular.org.br", "")}` : "#"} >          
          <Typography className="user_name">
            {params.location =="equipe" ? user.primeiro_nome : `${user.primeiro_nome} ${user.sobrenome}` }
          </Typography>
        </Link>
          <Typography className="user_profissao"
            variant="body2"
          >
            {user.formacao ? user.formacao : ""}
          </Typography>
          <Typography className="user_funcao"
            variant="body2"
          >
            {user.funcao}
          </Typography>
        {params.location == "associado" ?
        <a href={`mailto://${user.email}`}  target="_blank" rel="noopener noreferrer" title="Enviar e-mail">
          <Typography className="user_mail"
            variant="body2">
            {user.email}
          </Typography>
        </a>
        :
        <></>
        }
        </div>
      </div>
        {params.location == "associado" ?
        <div className="user_profile">
          <Typography className="user_subtitle"
            variant="subtitle1">
            Bio:
          </Typography>
          <Typography className="user_bio"
            variant="body2">
            {user.bio}
          </Typography>
          <Typography className="user_subtitle"
            variant="subtitle1">
            Formação:
          </Typography>
          <Typography className="user_formation"
            variant="body2">
              <ul>
              {user.formacoes ? user.formacoes.map(formacao => (<li>{formacao}</li>)) : ""}
              </ul>
          </Typography>
          <Typography className="user_subtitle"
            variant="subtitle1">
            Experiências:
          </Typography>
          <Typography className="user_pastrojects"
            variant="body2">
              <ul>
              {user.experiencias ? user.experiencias.map(experiencias => (<li>{experiencias}</li>)) : ""}
              </ul>
          </Typography>
          </div>
        :
        <></>
      }
    </Box>
  );
}