import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import {orderBy} from 'lodash';

const CardAno = (params) => {
    const ano = params.ano;
  const endPoint = 'anos';
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=${ano}&updaet=0`;
  const [pages, setPages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPages(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  if (isLoading) {
    return <CircularProgress className="loading" />;
  }


  return (
    <div>
        <h3>{ano}</h3>
          <Box className="transparencia_list">
          {orderBy(pages, ['name'], ['desc']).map(page => (
              <div className="balancete_item" key={page.id}>
                <p><a href={page["@microsoft.graph.downloadUrl"]}>{page.name}</a></p>
              </div>
          ))}
        </Box>
    </div>
  );
};

export default CardAno;

