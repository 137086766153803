import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import Balancetes from './demonstrativos';
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import {orderBy} from 'lodash';
import Documentos from './documentos';
import Assembleias from './assembleias';

const Transparencia = () => {

  useEffect(() => {
    document.title = 'Modular - ATHIS: Transparência';
  }, []);
  return (
    <div className="transparencia">
        <Assembleias />
        <Balancetes />
        <Documentos />
    </div>
  );
};

export default Transparencia;

