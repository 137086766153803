import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import UserCard from "./userCard";
import Box from "@mui/material/Box";
import {sortBy} from 'lodash';

function FixedEquipe(props) {
  return (
    <div className="fixed_equipe" ><h3>Equipe</h3></div>
  )
}

const Equipe = () => {
  const endPoint = 'associados';
 
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}`;
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  
  useEffect(() => {
    document.title = 'Modular - ATHIS: Equipe';
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setUsers(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  if (isLoading) {
    return <CircularProgress className="loading" />;
  }
return (<div>
  <Box className="equipe maincontent">
  <FixedEquipe />
<ul className="cards" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    {
      sortBy(users, ['primeiro_nome']).map(user => (
      <UserCard user={user} location="equipe" key={user.id}/>))
    }
  </ul></Box>
  </div>
)
};

export default Equipe;