import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Typography from '@mui/material/Typography';
import { Link, useParams } from "react-router-dom";
import UserCard from "./userCard";

export default function Associado(params) {
  const { idDoUserParam } = useParams();
  const endPoint = 'user';
 
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&nome=${idDoUserParam}`;
  const [user, setUser] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setUser(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

    if (isLoading) {
      return <CircularProgress className="loading" />;
    }
    return (
      <div>
        <Box className="associado maincontent">
          <UserCard user={user} location="associado"/>
        </Box>
      </div>
    );
  }