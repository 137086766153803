import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function FixedSobre(props) {
  return (
    <div className="fixed_sobre" >
      <div className="sobrecontent">
        <div className="pagetitle" >
            <h2 className="heading">Sobre</h2>
        </div>
        <div className="subtitle" >
          <div>
            <p>Somos uma associação mato-grossense que tem por objetivo oferecer assessoria técnica em arquitetura, urbanismo e engenharia para a parcela da população com renda de até 3 salários mínimos, contribuindo para assegurar o Direito à Moradia Digna.</p>
          </div>
        </div>
        <div className="sobrecontent">
          <div >
            <p><strong>Missão |</strong> Contribuir para a melhoria do Habitat urbano através da assessoria técnica.</p>
            <p><strong>Visão |</strong>Ser uma organização reconhecida pela contribuição na melhoria do habitat urbano em Mato Grosso.</p>
            <p><strong>Valores |</strong>Valorização das pessoas; ética; responsabilidade social; respeito à diversidade; sustentabilidade; autonomia.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Sobre(props) {
    useEffect(() => {
        document.title = 'Modular - ATHIS';
      }, []);
  return (
    <div className="sobre maincontent">
      <div className="sobre_wrapper">
          <FixedSobre />
          <div className="sobre_buttons">
            <Link to="/posts" ><Button variant="outlined" className="sobre_button">Acesse nosso blog</Button></Link>
            <Link to="/transparencia" ><Button variant="outlined" className="sobre_button">Transparência</Button></Link>
          </div>
        </div>
    </div>
  );
}