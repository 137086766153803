import { useEffect } from "react";
import Box from "@mui/material/Box";
import Sharepoint from "./sharepoint";
import Tags from "./tags";

function FixedAtuacao(props) {
  return (
    <div className="fixed_atuacao" >
      <div data-sek-level="column" data-sek-id="__nimble__e8e7ff461fc5" className="sek-column sek-col-base sek-col-100   "><div className="sek-column-inner "><div data-sek-level="module" data-sek-id="__nimble__789916288433" data-sek-module-type="czr_heading_module" className="sek-module main_title eds-on-scroll eds-scroll-visible animated fadeInLeft duration1" title=""><div className="sek-module-inner"><h2 className="sek-heading">Atuação</h2></div></div><div data-sek-level="section" data-sek-id="__nimble__9ae0c005d866" data-sek-is-nested="true" className="sek-section sek-has-modules   "><div className="sek-container-fluid"><div className="sek-row sek-sektion-inner"><div data-sek-level="column" data-sek-id="__nimble__fc4f936472cd" className="sek-column sek-col-base sek-col-50   "><div className="sek-column-inner "><div data-sek-level="module" data-sek-id="__nimble__df0fef6a3793" data-sek-module-type="czr_simple_html_module" className="sek-module eds-on-scroll eds-scroll-visible animated fadeInLeft delay1 duration1" title=""><div className="sek-module-inner"><div className="servicos_item">
        <i className="fas fa-angle-right"></i>
          <strong>projetos arquitetônicos para novas residências ou projetos de reformas de residências com ou sem ampliação</strong><p></p></div><div className="servico_texto">planta de implantação e cobertura, planta baixa, cortes, elevações, projeto as built, planilha de custos e especificações, manual didático com orientações sobre construção, manutenção e orientações para futuras intervenções construtivas</div><div></div><div className="servicos_item">
          <i className="fas fa-angle-right"></i>
          <strong>projetos urbanísticos</strong><p></p>
          </div>
          <div className="servico_texto">projetos de parcelamentos, de regularização fundiária e urbanística, de praças e equipamentos públicos</div>
          <div></div>
          <div className="servicos_item">
            <i className="fas fa-angle-right"></i>
            <strong>estudos urbanísticos que sirvam de alicerce na elaboração/alteração de legislação urbanística</strong>
            <p></p>
          </div>
          <div className="servicos_item">lei de uso e ocupação do solo, plano diretor, plano habitacional, plano de regularização fundiária, entre outros compreendendo as fases de levantamento, análise, diagnóstico e relatório.</div>
          </div>
          </div>
          </div>
          </div>
          <div data-sek-level="column" data-sek-id="__nimble__c139a13b3b52" className="sek-column sek-col-base sek-col-50   ">
            <div className="sek-column-inner ">
              <div data-sek-level="module" data-sek-id="__nimble__a85e9a0e0957" data-sek-module-type="czr_simple_html_module" className="sek-module eds-on-scroll eds-scroll-visible animated fadeInRight delay2 duration1" title="">
                <div className="sek-module-inner">
                  <div>
                    <i className="fas fa-check"></i>
                    <p>Priorizamos a participação do morador em todas as fases, desde a elaboração até a construção</p>
                    </div>
                    </div>
                    </div>
                    <div data-sek-level="module" data-sek-id="__nimble__ff5d85b686a4" data-sek-module-type="czr_simple_html_module" className="sek-module eds-on-scroll eds-scroll-visible animated fadeInRight delay3 duration1" title="">
                      <div className="sek-module-inner">
                        <div>
                          <i className="fas fa-check"></i>
                          <p>O uso de tecnologias como o BIM e o SIG auxiliam na elaboração de projetos mais eficientes e eficazes</p>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
  )
}

function Projetos() {
  useEffect(() => {
    document.title = 'Modular - ATHIS: Projetos';
  }, []);
  return (
    <div>
      <Box className="projetos maincontent">
        <FixedAtuacao />
      <Sharepoint categoria="Projeto" />
      </Box>
      </div>
  )
}

export default Projetos;
