import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PostSPCard from './PostSPCard';
import Tags from "./tags";

const Sharepoint = (params) => {
  const categoria = params.categoria;
  const { tagParam } = useParams();
  const endPoint = 'posts';
  
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0`;
  const [pages, setPages] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(false);

      
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPages(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  if (isLoading) {
    return <CircularProgress className="loading" />;
  }


  return (
    <div>
      <ul>
        <Tags location={categoria == 'Projeto' ? 'projetos' : 'posts'} selecionado={tagParam ? tagParam : 'todos'} />
        {pages.map(page => ((categoria === page.fields.Categoria) && page.fields.Status === "Publicado" && (tagParam ? page.fields.Tags_x003a_url.some(tag => tag['LookupValue'] === tagParam) : true) ? 
          <li className="cards_item"  key={page.id}>
            <PostSPCard postObject={page} location="lista"/>
          </li>:<p key={page.id}></p>
        ))}
      </ul>
    </div>
  );
};

export default Sharepoint;

