import React, { useEffect, useState, useRef } from 'react';
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';



export default function PostSPCard(params) {

    const [isLoading, setLoading] = useState(false);
    const page = params.postObject;
    const location = params.location;
    const contentHtml = page.fields.Conte_x00fa_do;
    const [newContentHtml, setNewContentHtml] = useState(contentHtml);
    const [imageId, setimageId] = useState([]);
    var miniatura;

    
    useEffect(() => {
      fetchData();
    }, []);
  
    const fetchData = () => {
      const endPoint = 'imagens';
      const imageEndpoint = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0&update=0`;
      fetch(imageEndpoint)
        .then((response) => response.json())
        .then((result) => {
          setimageId(result);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    if (location === "completo") {
      for (const imageItem of imageId) {
        var newImageSrcPath = imageItem.webUrl.replace("https://modularathis.sharepoint.com", "");
        var position = newContentHtml.search(newImageSrcPath);
        if (position > 0) {
          var fixedImagesHtml = newContentHtml.substr(0, position) + imageItem['@microsoft.graph.downloadUrl'] + newContentHtml.substr(position + newImageSrcPath.length);
          setNewContentHtml(fixedImagesHtml);
        }
      };
      return (
        <div>{isLoading ? <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)' }} variant="rectangular" width={210} height={25} animation="wave" className="skeleton_FE"/>: 
        <Box className="post maincontent">
          <div key={page.id} style={{ padding: "10px" }}>
            <h2><a href="/posts">{page.fields.Categoria}:</a> {page.fields.LinkTitle}</h2>
                <div className="author_card">
                    <h3>Por: {page.fields.Author}</h3>
                    <h3 className="author">Tags: {page.fields.Tags ? page.fields.Tags.map(tag => (tag.LookupValue + " ")) : false}</h3>
                </div> 
            <div dangerouslySetInnerHTML={{__html: newContentHtml}}></div>
            </div>
          </Box>
          }
        </div>
    
      );
    } else if (location === "lista") {
      for (const imageItem of imageId) {
        if (imageItem.webUrl === page.fields.miniatura.Url) {
          miniatura = imageItem['@microsoft.graph.downloadUrl'];
        }
    };
      return (
        <>{isLoading ? <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)' }} variant="rectangular" width={210} height={140} animation="wave" className="skeleton_FE"/>: 
        <Card sx={{ maxWidth: 345 }} className="card">
        <Link to={`/post/${page.fields.url}`}>
      <Typography variant="body2" color="text.secondary" className="card_excerpt" dangerouslySetInnerHTML={{__html: page.fields.Resumo}}></Typography>
        </Link>
        <CardMedia
          sx={{ height: 140 }}
          image={miniatura}
          title={page.fields.LinkTitle}
        />
        <CardContent>
          <Link to={`/post/${page.fields.url}`}>
            <Typography gutterBottom variant="h5" component="div" className="post_title_card">
              {page.fields.LinkTitle}
            </Typography>
          </Link>
          
        <p className="author">Por: <Link to={`/associado/${page.createdBy.user.email.replaceAll("@modular.org.br", "")}`} size="small">{page.createdBy.user.displayName}</Link></p>
        <p className="author">Tags: {page.fields.Tags.map(tag => (tag.LookupValue + " "))}</p>
      
        </CardContent>
          </Card>
    }</>
      );
    }

};