import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {sortBy} from 'lodash';

function FixedTags(props) {
  return (
    <div className="fixed_tags" ><h4>Filtrar por Tags</h4></div>
  )
}

const Tags = (params) => {
  const location = params.location;
  const selecionado = params.selecionado;
  const endPoint = 'tags';
 
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0&update=0`;
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setTags(result);
        setLoading(false);
      });
  }, [isLoading]);
  if (isLoading) {
    return <Box className="tags"><FixedTags /><CircularProgress className="loading" /></Box>;
  }
return (<div>
  <Box className="tags">
  <FixedTags />
<ul className="cards" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
  <Link to={`/${location}`}><button className={selecionado === "todos" ? "selecionado": ""}>Todos</button></Link>
    {sortBy(tags, ['fields.Title']).map(tag => (
      <Link key={tag.id} to={`/${location}/${tag.fields.url}`}><button className={selecionado === tag.fields.url ? "selecionado": ""}>{tag.fields.Title}</button></Link>))
    }
  </ul></Box>
  </div>
)
};

export default Tags;