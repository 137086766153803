import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Home(props) {
  useEffect(() => {
        document.title = 'Modular - ATHIS';
      }, []);
      
  return (
    <div>
      <Box className="home maincontent">
        <div className="home_content" >
            <h3> Moradia Digna</h3>
            <h4> é um direito de todos.</h4>
            <p>E o objetivo da Modular é contribuir para assegurar este direito a quem mais precisa</p>
            <Link to="/sobre"><Button className="homeButton">Saiba Mais</Button></Link>
          </div>
        </Box>
    </div>
  );
}