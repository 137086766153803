import { useState } from "react";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";
import Hamburger from 'hamburger-react'

function ButtonIcon(props) {
  return (
    <div className="buttonIcon" ></div>
  )
}

export default function SideBar(props) {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  return (
      <Box className="sidebar">
      <div className="sidebarcontent" >
        <div className="logo_bar">
          <NavLink to="/">
            <img src="/media/logo.webp" width={150} alt="Modular Assessoria Técnica"/>
          </NavLink>
        </div>
        <div className={"menu_bar " + (isOpen ? 'open' : 'closed')}>
            <NavLink to="/" onClick={toggleOpen} activeclassname="active">
              <div className="Button">
              <ButtonIcon />Início
              </div>
            </NavLink>
            <NavLink to="/sobre" onClick={toggleOpen} activeclassname="active">
              <div className="Button">
              <ButtonIcon />Sobre
              </div>
            </NavLink>
            <NavLink to="/posts" onClick={toggleOpen}  activeclassname="active">
              <div className="Button">
                <ButtonIcon />Blog
              </div>
            </NavLink>
            <NavLink to="/projetos" onClick={toggleOpen}  activeclassname="active">
              <div className="Button">
              <ButtonIcon />Atuação
              </div>
            </NavLink>
            <NavLink to="/equipe" onClick={toggleOpen}  activeclassname="active">
              <div className="Button">
              <ButtonIcon />Equipe
              </div>
            </NavLink>
            <NavLink to="/contato" onClick={toggleOpen}  activeclassname="active">
              <div className="Button">
              <ButtonIcon />Contato
              </div>
            </NavLink>
          </div>
          <div className="mobile_social">
            <div className="social_bar" >
              <div className="facebook_bar">
                <a target="_blank" href="https://www.facebook.com/modular.at/"><img width="35" height="35" src="/media/facebook.webp" className="attachment-thumbnail size-thumbnail no-lazy" alt="" /></a>
              </div>
              <div className="instagram_bar" >
                <a target="_blank" href="https://www.instagram.com/modular.at/"><img width="35" height="35" src="/media/instagram.webp" className="attachment-thumbnail size-thumbnail no-lazy" alt="" /></a>
              </div>
              <div className="linkedin_bar" >
                <a target="_blank" href="https://www.linkedin.com/company/modular-at/"><img width="35" height="35" src="/media/linkedin.webp" className="attachment-thumbnail size-thumbnail no-lazy" alt="" /></a>
              </div>
            </div>
            <div className="menu_button">
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </div>
        </div>
        </Box>
  );
}
