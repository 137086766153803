import React, { useEffect, useState } from 'react';
import { CircularProgress, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import {orderBy} from 'lodash';

const Assembleias = (params) => {
  const endPoint = 'assembleias';
  var dataHora;
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0&update=0`;
  const [pages, setPages] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPages(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <div>
        <h3>Assembleias</h3>
        {isLoading
          ?
            <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)' }} variant="rectangular" width={210} height={25} animation="wave" className="skeleton_FE"/>
          :
            <Box className="transparencia_list">
            {orderBy(pages, ['fields.DataeHora'], ['desc']).map(page => (
              dataHora = new Date(page.fields.DataeHora), 
              <Card sx={{ maxWidth: 345 }} className="transparencia_card" key={page.id}>
                <div className="assembleia_item"  key={page.id}>
                  <h4>{page.fields.Title}</h4>
                  <p>Dia e Horário: {dataHora.toLocaleString('pt-BR', { timeZone: 'America/Cuiaba' })}</p>
                  <p><a href={page.fields.Edital.Url} target="_blank">Edital</a> - <a href={page.fields.Ata.Url} target="_blank">Ata</a></p>
                </div>
              </Card>
            ))}
            </Box>
        }
    </div>
  );
};

export default Assembleias;

