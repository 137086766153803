import React, { useEffect, useState } from 'react';
import { CircularProgress, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import {orderBy} from 'lodash';
import AnoCard from './balanceteAnoCard';

const Balancetes = (params) => {
  const endPoint = 'demonstrativos';
  var dataHora;
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0&update=0`;
  const [pages, setPages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPages(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <div>
        <h3>Demonstrativos Contábeis</h3>
        {isLoading
          ?
            <Skeleton sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)' }} variant="rectangular" width={210} height={25} animation="wave" className="skeleton_FE"/>
          :
            <Box className="transparencia_list">
            {orderBy(pages, ['name'], ['desc']).map(page => (
              <Card sx={{ maxWidth: 345 }} className="transparencia_card" key={page.id}>
                <AnoCard ano={page.name}/>
              </Card>
            ))}
          </Box>
        }
    </div>
  );
};

export default Balancetes;

