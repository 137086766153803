import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PostSPCard from './PostSPCard';

const PostSP = () => {
    const { postUrl } = useParams();
  const endPoint = 'posts';
  
  const url = `https://api.modularat.com.br/?endPoint=${endPoint}&chave=0&update=0`;
  const [pages, setPages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setPages(result);
        setLoading(false);
      });
  }, [isLoading]);
  if (isLoading) {
    return <CircularProgress className="loading" />;
  }

  return (
    <div>
      {pages.filter(filterPage => (filterPage.fields.url == postUrl)).map(page => (
        <PostSPCard postObject={page} location="completo"/>
      ))}
    </div>
  );
};

export default PostSP;

